<template>
  <v-card outlined>
    <v-row
      ><v-col>
        <v-list-item three-line>
          <v-list-item-content>
            <v-row no-gutters class="mb-4">
              <v-col class="overline" align-self="start" :md="3">
                {{ item.title }}
              </v-col>
              <v-col :md="3" v-if="item.percentile">
                <report-score raw :percentile="item.percentile" />
              </v-col>
            </v-row>
            <v-list-item-subtitle>{{
              loggedAt(item.timestamp)
            }}</v-list-item-subtitle>
            <v-list-item-title class="mb-1">{{
              item.description
            }}</v-list-item-title>
            <VideoPlayer
              v-if="item.type === 'video'"
              :assessment-id="assessmentId"
              :candidate-id="candidateId"
              :log="item.log"
              class="mt-2"
            />
            <report-skill-question
              v-if="item.type === 'submitted'"
              :question-answer="item.question"
            />
          </v-list-item-content>
        </v-list-item> </v-col
    ></v-row>
  </v-card>
</template>
<script>
import moment from 'moment'
import ReportSkillQuestion from './ReportSkillQuestion.vue'
import ReportScore from './ReportScore.vue'
import VideoPlayer from './VideoPlayer.vue'

export default {
  components: { ReportSkillQuestion, VideoPlayer, ReportScore },
  props: {
    item: {
      type: Object,
      required: true,
    },
    candidateId: {
      type: String,
      required: true,
    },
    assessmentId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {},
  methods: {
    loggedAt(timestamp) {
      return moment.unix(timestamp / 1000).format('hh:mm:ss A')
    },
  },
  created() {},
}
</script>
