<template>
  <v-card outlined rounded="xl">
    <v-card-title>
      <v-row
        class="text-h5 font-weight-bold d-flex pa-0"
        align="center"
        justify="center"
      >
        <v-col cols="1">
          <v-btn class="text-none" color="#122333" @click="$router.go(-1)">
            <v-icon color="white">
              {{ mdiArrowLeft }}
            </v-icon>
          </v-btn>
        </v-col>
        <v-col class="ml-5" id="report-start">
          {{ candidateReport?.getName() }}
          <div class="text-subtitle-2 gray--text font-weight-regular">
            {{ candidateReport?.getEmail() }}
          </div>
          <div>
            <v-rating
              color="primary"
              hover
              length="5"
              size="48"
              v-model="candidateRating"
              @input="candidateRatingUpdate"
            ></v-rating>
          </div>
        </v-col>
        <v-col>
          <email></email>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text class="text-none px-8">
      <v-row>
        <v-col cols="12" md="5" class="right__border" id="report-overview">
          <!-- test details -->
          <v-row>
            <v-col cols="4" class="text-body-1 font-weight-medium black--text">
              Test Name
            </v-col>
            <v-col cols="7" class="text-body-1">
              {{ assessment?.getTitle() }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="text-body-1 font-weight-medium black--text">
              Invited
            </v-col>
            <v-col cols="7" class="text-body-1">
              {{ inviteDate }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="text-body-1 font-weight-medium black--text">
              Started
            </v-col>
            <v-col cols="7" class="text-body-1">
              <span v-if="hasStarted">
                {{ startedDate }}
              </span>
              <span v-else> - </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="text-body-1 font-weight-medium black--text">
              Completed
            </v-col>
            <v-col cols="7" class="text-body-1">
              <span v-if="hasCompleted">
                {{ completedDate }}
              </span>
              <span v-else> - </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="text-body-1 font-weight-medium black--text">
              Test Duration
            </v-col>
            <v-col cols="7" class="text-body-1">
              {{ totalTestDuration }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" class="text-body-1 font-weight-medium black--text">
              Status
            </v-col>
            <v-col cols="7" class="text-body-1">
              <v-chip :color="candidateStatusColor">
                {{ candidateStatus }}
              </v-chip>
            </v-col>
          </v-row>
          <v-row id="report-stage">
            <v-col cols="4" class="text-body-1 font-weight-medium black--text">
              Stage
            </v-col>
            <v-col cols="7" class="text-body-1 mt-n1">
              <v-select
                dense
                outlined
                :items="testStagesSelector"
                item-text="text"
                item-value="stage"
                v-model="currentStage"
                @change="candidateStageUpdate"
              >
              </v-select>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="4" class="black--text right__border">
          <v-card class="pa-0 ma-0 mx-2" rounded="0" elevation="0">
            <v-card-text class="pa-0 ma-0 black--text" id="report-skills">
              <v-row class="text-body-1 font-weight-medium">
                <v-col cols="6">Skills</v-col>
                <v-col>Score</v-col>
              </v-row>
              <v-row
                class="text-body-1 px-0 pb-3"
                v-for="sk in testSkills"
                :key="skillScoreKey(sk)"
              >
                <v-col>{{ sk.title }}</v-col>
                <v-col>
                  <report-score :percentile="getSkillPercentile(sk.id)" />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="px-0 ma-0 mt-8">
              <v-row class="text-body-1 font-weight-medium score__border">
                <v-col cols="6">Overall Score:</v-col>
                <v-col
                  ><report-score
                    v-if="hasCompleted"
                    :percentile="candidateReport?.getPercentile()"
                  />
                  <span v-else>-</span>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" md="3" class="black--text">
          <v-card class="pa-0 ma-0" elevation="0">
            <v-card-text class="pa-0 ma-0 black--text" id="report-anticheat">
              <v-row class="text-body-1 font-weight-medium">
                <v-col> Anti Cheating Report </v-col>
              </v-row>
              <v-row v-for="ac in antiCheats" :key="acKey(ac)">
                <v-col class="text-body-1">
                  {{ ac.title }}
                </v-col>
                <v-col>
                  {{ ac.value }}
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="black--text">
        <v-col cols="12">
          <v-row>
            <v-col>
              <div class="text-body-1 font-weight-medium">Private Notes</div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div class="textarea-container">
                <div class="textarea-wrapper">
                  <v-textarea
                    id="notes"
                    v-model="candidateNotes"
                    rows="5"
                    outlined
                    placeholder="Candidate Notes"
                    class="custom-textarea"
                  ></v-textarea>
                  <button class="copy-button" @click="copyText">
                    <v-icon :color="copyIconColor">
                      {{ mdiContentCopy }}
                    </v-icon>
                  </button>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="d-flex flex-column-reverse">
          <div class="pb-8 d-flex justify-space-between">
            <v-btn
              class="text-body-1 text-none"
              color="primary"
              :disabled="!isDirty"
              @click="saveCandidateInfo"
            >
              Save Notes
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <v-row v-if="timeline?.length > 0">
        <v-col>
          <h3 class="section-title">Test timeline</h3>
        </v-col>
      </v-row>
      <v-row v-if="timeline?.length > 0">
        <v-col>
          <v-timeline dense>
            <v-timeline-item
              v-for="(item, idx) in timeline"
              :key="timelineKey(item, idx)"
              :icon="item.icon"
              :icon-color="item.color"
              color="white"
            >
              <report-timeline-item
                :item="item"
                :assessment-id="assessment?.getId()"
                :candidate-id="candidateReport?.getCandidateId()"
              ></report-timeline-item>
            </v-timeline-item>
          </v-timeline>
        </v-col>
      </v-row>
      <v-row
        v-if="
          candidateReport?.getSkillScoresList() &&
          candidateStatus === 'Completed'
        "
      >
        <v-col cols="12">
          <v-card-title class="primary--text"> Skill breakdown </v-card-title>
          <v-card class="ma-0 pa-5" elevation="0" outlined>
            <v-card-text id="report-answers">
              <skill-report />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="resumeLink && resumeLink !== ''">
        <v-col>
          <v-card-title class="primary--text"> Resume </v-card-title>
          <pdf :src="resumeLink"></pdf>
        </v-col>
      </v-row>
    </v-card-text>
    <v-tour name="sampleReportTour" :steps="sampleReportTour"></v-tour>
  </v-card>
</template>
<script>
import {
  mdiArrowLeft,
  mdiCodeBraces,
  mdiContentCopy,
  mdiPlay,
  mdiStop,
  mdiTab,
  mdiVideo,
  mdiGestureTapButton,
  mdiCodeJson,
  mdiListStatus,
} from '@mdi/js'
import moment from 'moment'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import formattedTimeMixin from '@/mixins/formattedTimeMixin'
import SkillReport from './CandidateSkillReport.vue'
import ReportScore from './ReportScore.vue'
import ReportSkillQuestion from './ReportSkillQuestion.vue'
import ReportTimelineItem from './ReportTimelineItem.vue'
import pdf from 'vue-pdf'
import Email from './Email.vue'
import { prettyPercent, buildKey } from '@/utils/helper'
import { ApiCallStatus } from '@/utils/api'
import 'codedrills_proto/io/codedrills/proto/content/content_pb'

const AntiCheatingSettings =
  proto.io.codedrills.proto.content.AntiCheatingSettings
const ContentType = proto.io.codedrills.proto.content.ContentType

export default {
  components: {
    SkillReport,
    pdf,
    Email,
    ReportScore,
    ReportSkillQuestion,
    ReportTimelineItem,
  },
  data() {
    return {
      mdiArrowLeft,
      mdiContentCopy,
      editing: false,
      candidateRating: 0,
      candidateNotes: '',
      updatedStage: null,
      videoBlob: null,
      videoType: null,
      mdiPlay,
      sampleReportTour: [
        {
          target: '#report-start',
          header: {
            title: 'Candidate report',
          },
          content:
            'CodeDrills produces a detailed report for each candidate. Click next to know about each part of the report.',
        },
        {
          target: '#report-overview',
          header: {
            title: 'Report overview',
          },
          content:
            'This section lists some stats about the assessment - like when the candidate started and completed this assessment, status etc.',
        },
        {
          target: '#report-skills',
          header: {
            title: 'Skills',
          },
          content:
            "Each assessment is built from a set of skills. This section lists the candidate's performance on each skill. The score is normalized to a percentage. <br/> This section also lists the overall score for the assessment.",
        },
        {
          target: '#report-anticheat',
          header: {
            title: 'Anti-cheating report',
          },
          content:
            'This section lists all the proctoring information like the number of times the candidate switched tab or exited full screen. This information can be used to determine whether the candidate cheated or not.',
        },
        {
          target: '#report-answers',
          header: {
            title: 'Answer details',
          },
          content:
            "This section lists all the questions asked for this candidate and the candidate's answers for each of them. For coding questions we give the best solution of the candidate. For MCQs we display the correct answer.",
        },
        {
          target: '#report-notes',
          header: {
            title: 'Notes',
          },
          content:
            'You can add any notes you want about the candidate here. These notes will be visible to all the recruiters in your organization. You can also edit these notes later.',
        },
        {
          target: '#report-stage',
          header: {
            title: 'Stage',
          },
          content:
            'You can progress the candidate through the interview progress by changing the stage here',
        },
        {
          target: '#report-send-mail',
          header: {
            title: 'Send mail',
          },
          content:
            'You can send an update email to the candidate from here. You can choose from a set of pre-defined templates or write your own mail. <br/> <br/> <br/> Use CodeDrills to cut down on the time spent on screening candidates. Mail us on hello@codedrills.io for any queries or suggestions.',
        },
      ],
      copyIconColor: 'white',
      isDirty: false,
    }
  },
  mixins: [formattedTimeMixin],
  computed: {
    ...mapState('recruiter', [
      'assessment',
      'assessmentView',
      'candidateReport',
      'fetchAssessmentStatus',
      'fetchCandidateReportStatus',
    ]),
    ...mapGetters('recruiter', [
      'testStatusPrettyMessage',
      'cheatingPrettyMessage',
      'testStagesSelector',
      'testStatusColor',
      'testStages',
      'emailTypes',
    ]),
    currentStage: {
      get: function () {
        let stage = this.candidateReport?.getStage()
        if (stage === 0) stage = 1
        return this.testStages[stage]
      },
      set: function (val) {
        this.updatedStage = val
      },
    },
    overallScore() {
      if (this.candidateReport?.getSkillScoresList()?.length > 0) {
        if (Number.isNaN(this.candidateReport.getOverallScore())) {
          return `0%`
        } else {
          return prettyPercent(this.candidateReport.getOverallScore())
        }
      } else {
        return '-'
      }
    },
    testSkills() {
      if (this.fetchAssessmentStatus === ApiCallStatus.SUCCESS) {
        const skillIds = this.assessment
          ?.getDataView()
          ?.getTestView()
          ?.getSkillsList()
        const childPreviews = this.assessmentView?.getChildPreviewsList()
        const skillsText = []
        childPreviews?.forEach((p) => {
          if (
            skillIds.includes(p?.getContentPreview()?.getId()) &&
            p?.getContentPreview()?.getContentType() === 7
          ) {
            skillsText.push({
              title: p?.getContentPreview()?.getTitle(),
              id: p?.getContentPreview()?.getId(),
            })
          }
        })
        return skillsText
      }
    },
    antiCheats() {
      console.log('Assessement infois ...', this.assessment?.toObject())
      if (this.fetchCandidateReportStatus === ApiCallStatus.SUCCESS) {
        const antiCheatingReport = this.candidateReport?.getAntiCheatingReport()
        const msgs = []
        msgs.push({
          title: 'Disconnected',
          value: antiCheatingReport?.getDisconnectedCount() ?? 0,
        })
        msgs.push({
          title: 'Exited full screen',
          value: antiCheatingReport?.getFullScreenExitCount() ?? 0,
        })
        msgs.push({
          title: 'Switched tab',
          value:
            (antiCheatingReport?.getTabSwitchCount() ?? 0) +
            (antiCheatingReport?.getWindowSwitchCount() ?? 0),
        })

        return msgs
      }
    },
    candidateStatus() {
      return this.testStatusPrettyMessage[this.candidateReport?.getTestStatus()]
    },
    candidateStatusColor() {
      return this.testStatusColor[this.candidateReport?.getTestStatus()]
    },
    inviteDate() {
      if (this.fetchCandidateReportStatus) {
        return moment
          .unix(this.candidateReport?.getInviteDate() / 1000)
          .format('hh:mm A, DD/MM/YYYY')
      }
    },
    hasStarted() {
      return this.candidateReport?.getTestTimeInfo()?.getStartedAt() > 0
    },
    hasCompleted() {
      return this.candidateReport?.getTestTimeInfo()?.getEndedAt() > 0
    },
    startedDate() {
      return moment
        .unix(this.candidateReport?.getTestTimeInfo()?.getStartedAt() / 1000)
        .format('hh:mm A, DD/MM/YYYY')
    },
    completedDate() {
      return moment
        .unix(this.candidateReport?.getTestTimeInfo()?.getEndedAt() / 1000)
        .format('hh:mm A, DD/MM/YYYY')
    },
    totalTestDuration() {
      return this.displayTime(
        this.candidateReport?.getTestTimeInfo()?.getDuration(),
      )
    },
    resumeLink() {
      var link = this.candidateReport?.getProfile()?.getResumeLink()
      console.log('Resume link is ...', link)
      return link
    },
    timeline() {
      if (this.fetchCandidateReportStatus !== ApiCallStatus.SUCCESS) {
        return []
      }
      // Construct a sorted by time array of timeline events
      var timeline = []
      // Add test time info
      var testTimeInfo = this.candidateReport?.getTestTimeInfo()

      var procLogs =
        this.candidateReport
          ?.getAntiCheatingReport()
          ?.getProctoringLogsList() || []

      // Add anticheatings based on type
      for (const entry of procLogs) {
        console.log('entry...', entry.toObject())
        switch (entry.getProctoringEvent()) {
          case AntiCheatingSettings.ANTI_CHEATING_VIDEO_RECORDING:
            timeline.push({
              timestamp: entry.getTimestamp(),
              type: 'video',
              title: 'Video Recording',
              description: "Candidate's video recording",
              icon: mdiVideo,
              log: entry.getLog(),
              color: 'orange',
            })
            break
          case AntiCheatingSettings.ANTI_CHEATING_TAB_CHANGE:
            timeline.push({
              timestamp: entry.getTimestamp(),
              type: 'tab',
              title: 'Tab Change',
              description: 'Candidate switched tabs',
              icon: mdiTab,
              log: entry.getLog(),
              color: 'red',
            })
            break
          case AntiCheatingSettings.ANTI_CHEATING_FULL_SCREEN:
            timeline.push({
              timestamp: entry.getTimestamp(),
              type: 'fullscreen',
              title: 'Full Screen',
              description: 'Candidate exited full screen',
              icon: mdiFullscreen,
              log: entry.getLog(),
              color: 'red',
            })
            break
        }
      }

      // Add entry for each question
      var skills = this.candidateReport?.getSkillScoresList() || []
      for (const skill of skills) {
        const questions = skill.getQuestionAnswerList()
        for (const question of questions) {
          var icon = mdiGestureTapButton
          switch (question.getQuestionView()?.getType()) {
            case ContentType.MCQ:
              icon = mdiListStatus
              break
            case ContentType.PROBLEM:
              icon = mdiCodeBraces
              break
            case ContentType.SUBJECTIVE:
              icon = mdiGestureTapButton
              break
          }
          if (question?.getTimeInfo()?.getStartedAt()) {
            timeline.push({
              timestamp: question.getTimeInfo().getEndedAt(),
              type: 'submitted',
              title: 'Answer submitted',
              description: 'Candidate submitted an answer',
              icon: icon,
              color: 'green',
              question: question,
              percentile: question.getPercentile(),
            })
          }
          // For problems, add all submissions
          console.log('Question is ...', question?.toObject())
          if (question?.getCompleteAnswerData()?.getProblemAnswerData()) {
            const submissions = question
              ?.getCompleteAnswerData()
              ?.getProblemAnswerData()
              ?.getSubmissionPreviewList()
            for (const submission of submissions) {
              console.log('Submission is ...', submission.toObject())
              timeline.push({
                timestamp: submission.getSubmittedOn(),
                type: 'submission',
                title: 'Submission',
                description:
                  'Candidate attempted a submission with score ' +
                  prettyPercent(submission.getScore()),
                icon: mdiCodeJson,
                color: 'green',
              })
            }
          }
        }
      }
      if (testTimeInfo) {
        if (testTimeInfo?.getStartedAt()) {
          timeline.push({
            timestamp: testTimeInfo?.getStartedAt(),
            type: 'test',
            title: 'Test Started',
            description: 'Candidate started the test',
            icon: mdiPlay,
            color: 'grey',
          })
        }
        if (testTimeInfo?.getEndedAt()) {
          timeline.push({
            timestamp: testTimeInfo?.getEndedAt(),
            type: 'test',
            title: 'Test Ended',
            description: 'Candidate ended the test',
            icon: mdiStop,
            color: 'grey',
          })
        }
      }
      // Sort by time
      timeline.sort((a, b) => a.timestamp - b.timestamp)

      return timeline
    },
  },
  methods: {
    ...mapActions('recruiter', [
      'emailCandidate',
      'fetchAssessment',
      'updateCandidateStage',
      'updateCandidateNotes',
      'fetchCandidateReport',
      'updateCandidateRating',
    ]),
    copyText() {
      navigator.clipboard
        .writeText(this.candidateNotes)
        .then(() => {
          console.log('Text copied to clipboard')
          this.copyIconColor = 'green'
          // You can optionally show a success message or perform other actions here
        })
        .catch((error) => {
          console.error('Failed to copy text: ', error)
          // You can handle the error or show an error message here
        })
    },
    getVideoBlob(url, item) {
      console.log('Calling get video ...')
      this.getVideo({
        url: url,
        testId: this.assessment?.getId(),
        candidateId: this.candidateReport?.getCandidateId(),
      }).then((res) => {
        console.log('Response: ', res)
        item.videoType = res.getType()
        item.videoBlob = res.getVideo_asU8()
        console.log(
          'Single candidate Video blob: and type ',
          item.videoBlob,
          item.videoType,
        )
      })
    },
    getSkillPercentile(id) {
      const skillScores = this.candidateReport?.getSkillScoresList() ?? []
      if (skillScores.length > 0) {
        for (const skill of skillScores) {
          if (skill.getSkillPreview()?.getId() === id) {
            return skill?.getPercentile()
          }
        }
      } else return undefined
    },
    candidateRatingUpdate() {
      this.updateCandidateRating({
        testId: this.assessment?.getId(),
        cid: this.candidateReport?.getCandidateId(),
        rating: this.candidateRating,
      })
        .then((r) => {
          this.$store.dispatch('notifs/addNotif', {
            text: `Updated Successfully!`,
            type: 'success',
            duration: 1000,
          })
        })
        .catch((err) => {
          this.$store.dispatch('notifs/addNotif', {
            text: `Error! Please refresh and try again!`,
            type: 'error',
          })
        })
    },
    candidateStageUpdate() {
      this.updateCandidateStage({
        testId: this.assessment?.getId(),
        cid: this.candidateReport?.getCandidateId(),
        stage: this.updatedStage,
      })
        .then((r) => {
          this.$store.dispatch('notifs/addNotif', {
            text: `Updated Successfully!`,
            type: 'success',
            duration: 1000,
          })
        })
        .catch((err) => {
          this.$store.dispatch('notifs/addNotif', {
            text: `Error! Please refresh and try again!`,
            type: 'error',
          })
        })
    },
    saveCandidateInfo() {
      this.updateCandidateNotes({
        testId: this.assessment?.getId(),
        cid: this.candidateReport?.getCandidateId(),
        note: this.candidateNotes,
      })
        .then((r) => {
          this.$store.dispatch('notifs/addNotif', {
            text: `Updated Successfully!`,
            type: 'success',
          })
          this.isDirty = false
        })
        .catch((err) => {
          this.$store.dispatch('notifs/addNotif', {
            text: `Error! Please refresh and try again!`,
            type: 'error',
          })
        })
    },
    fetchReport() {
      this.candidateReport = null
      this.fetchCandidateReport({
        cid: this.$route.params.userId,
        testId: this.assessment?.getId(),
      })
        .then(() => {
          this.candidateRating = this.candidateReport?.getRating()
          this.candidateNotes = this.candidateReport?.getNotes()
          this.updatedStage = this.testStages[this.candidateReport?.getStage()]
          if (
            this.candidateReport?.getEmail() == 'hello+sample1@codedrills.io'
          ) {
            this.$tours['sampleReportTour'].start()
          }
        })
        .catch((err) => {
          this.$store.dispatch('notifs/addNotif', {
            text: `Something went wrong! Please refresh and try again!`,
            type: 'error',
          })
        })
    },
    acKey(ac) {
      return buildKey('acKey', [this.$route.path, ac.title])
    },
    skillScoreKey(skill) {
      return buildKey('skillScoreKey', [this.$route.path, skill.id])
    },
    timelineKey(item, idx) {
      return buildKey('timelineKey', [this.$route.path, item.type, idx])
    },
  },
  created() {
    if (!this.assessment) {
      this.fetchAssessment({ url: this.$route.params.url }).then((r) => {
        console.log('assessment fetch')
        if (!this.candidate) {
          this.fetchReport()
        }
      })
    } else {
      this.fetchReport()
    }
  },
  watch: {
    candidateNotes: function (newVal, oldVal) {
      //console.log("candidate info changed ...", newVal, this.candidateReport?.getNotes());
      this.isDirty = newVal !== this.candidateReport?.getNotes()
    },
  },
}
</script>
<style scoped>
.right__border {
  border-right: 1px solid #c4c4c4;
}

.score__border {
  border-top: 2px solid #c4c4c4;
  border-bottom: 2px solid #c4c4c4;
}

.textarea-container {
  position: relative;
}

.textarea-wrapper {
  position: relative;
}

.custom-textarea {
  padding-right: 10px; /* Adjust the padding to make space for the button */
}

.copy-button {
  position: absolute;
  top: 72%;
  right: 18px;
  transform: translateY(-50%);
  padding: 5px 10px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 5px;
}
</style>
