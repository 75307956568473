var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-row', [_c('v-col', [_c('v-list-item', {
    attrs: {
      "three-line": ""
    }
  }, [_c('v-list-item-content', [_c('v-row', {
    staticClass: "mb-4",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "overline",
    attrs: {
      "align-self": "start",
      "md": 3
    }
  }, [_vm._v(" " + _vm._s(_vm.item.title) + " ")]), _vm.item.percentile ? _c('v-col', {
    attrs: {
      "md": 3
    }
  }, [_c('report-score', {
    attrs: {
      "raw": "",
      "percentile": _vm.item.percentile
    }
  })], 1) : _vm._e()], 1), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.loggedAt(_vm.item.timestamp)))]), _c('v-list-item-title', {
    staticClass: "mb-1"
  }, [_vm._v(_vm._s(_vm.item.description))]), _vm.item.type === 'video' ? _c('VideoPlayer', {
    staticClass: "mt-2",
    attrs: {
      "assessment-id": _vm.assessmentId,
      "candidate-id": _vm.candidateId,
      "log": _vm.item.log
    }
  }) : _vm._e(), _vm.item.type === 'submitted' ? _c('report-skill-question', {
    attrs: {
      "question-answer": _vm.item.question
    }
  }) : _vm._e()], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }