var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [!_vm.videoBlob ? _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function ($event) {
        return _vm.loadVideo();
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "primary"
    }
  }, [_vm._v(_vm._s(_vm.mdiPlay))]), _vm._v(" Play ")], 1) : _vm._e(), _vm.videoBlob ? _c('video', {
    ref: "video",
    attrs: {
      "src": _vm.source,
      "controls": ""
    }
  }) : _vm.loadingError ? _c('error', [_vm._v("An error ocurred while loading this page. Please try again")]) : _vm.loadingInProgress ? _c('loader') : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }