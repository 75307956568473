var render = function render(){
  var _vm$candidateReport, _vm$candidateReport2, _vm$assessment, _vm$candidateReport3, _vm$timeline, _vm$timeline2, _vm$candidateReport5;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "outlined": "",
      "rounded": "xl"
    }
  }, [_c('v-card-title', [_c('v-row', {
    staticClass: "text-h5 font-weight-bold d-flex pa-0",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "1"
    }
  }, [_c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "#122333"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "white"
    }
  }, [_vm._v(" " + _vm._s(_vm.mdiArrowLeft) + " ")])], 1)], 1), _c('v-col', {
    staticClass: "ml-5",
    attrs: {
      "id": "report-start"
    }
  }, [_vm._v(" " + _vm._s((_vm$candidateReport = _vm.candidateReport) === null || _vm$candidateReport === void 0 ? void 0 : _vm$candidateReport.getName()) + " "), _c('div', {
    staticClass: "text-subtitle-2 gray--text font-weight-regular"
  }, [_vm._v(" " + _vm._s((_vm$candidateReport2 = _vm.candidateReport) === null || _vm$candidateReport2 === void 0 ? void 0 : _vm$candidateReport2.getEmail()) + " ")]), _c('div', [_c('v-rating', {
    attrs: {
      "color": "primary",
      "hover": "",
      "length": "5",
      "size": "48"
    },
    on: {
      "input": _vm.candidateRatingUpdate
    },
    model: {
      value: _vm.candidateRating,
      callback: function ($$v) {
        _vm.candidateRating = $$v;
      },
      expression: "candidateRating"
    }
  })], 1)]), _c('v-col', [_c('email')], 1)], 1)], 1), _c('v-card-text', {
    staticClass: "text-none px-8"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "right__border",
    attrs: {
      "cols": "12",
      "md": "5",
      "id": "report-overview"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "text-body-1 font-weight-medium black--text",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" Test Name ")]), _c('v-col', {
    staticClass: "text-body-1",
    attrs: {
      "cols": "7"
    }
  }, [_vm._v(" " + _vm._s((_vm$assessment = _vm.assessment) === null || _vm$assessment === void 0 ? void 0 : _vm$assessment.getTitle()) + " ")])], 1), _c('v-row', [_c('v-col', {
    staticClass: "text-body-1 font-weight-medium black--text",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" Invited ")]), _c('v-col', {
    staticClass: "text-body-1",
    attrs: {
      "cols": "7"
    }
  }, [_vm._v(" " + _vm._s(_vm.inviteDate) + " ")])], 1), _c('v-row', [_c('v-col', {
    staticClass: "text-body-1 font-weight-medium black--text",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" Started ")]), _c('v-col', {
    staticClass: "text-body-1",
    attrs: {
      "cols": "7"
    }
  }, [_vm.hasStarted ? _c('span', [_vm._v(" " + _vm._s(_vm.startedDate) + " ")]) : _c('span', [_vm._v(" - ")])])], 1), _c('v-row', [_c('v-col', {
    staticClass: "text-body-1 font-weight-medium black--text",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" Completed ")]), _c('v-col', {
    staticClass: "text-body-1",
    attrs: {
      "cols": "7"
    }
  }, [_vm.hasCompleted ? _c('span', [_vm._v(" " + _vm._s(_vm.completedDate) + " ")]) : _c('span', [_vm._v(" - ")])])], 1), _c('v-row', [_c('v-col', {
    staticClass: "text-body-1 font-weight-medium black--text",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" Test Duration ")]), _c('v-col', {
    staticClass: "text-body-1",
    attrs: {
      "cols": "7"
    }
  }, [_vm._v(" " + _vm._s(_vm.totalTestDuration) + " ")])], 1), _c('v-row', [_c('v-col', {
    staticClass: "text-body-1 font-weight-medium black--text",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" Status ")]), _c('v-col', {
    staticClass: "text-body-1",
    attrs: {
      "cols": "7"
    }
  }, [_c('v-chip', {
    attrs: {
      "color": _vm.candidateStatusColor
    }
  }, [_vm._v(" " + _vm._s(_vm.candidateStatus) + " ")])], 1)], 1), _c('v-row', {
    attrs: {
      "id": "report-stage"
    }
  }, [_c('v-col', {
    staticClass: "text-body-1 font-weight-medium black--text",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" Stage ")]), _c('v-col', {
    staticClass: "text-body-1 mt-n1",
    attrs: {
      "cols": "7"
    }
  }, [_c('v-select', {
    attrs: {
      "dense": "",
      "outlined": "",
      "items": _vm.testStagesSelector,
      "item-text": "text",
      "item-value": "stage"
    },
    on: {
      "change": _vm.candidateStageUpdate
    },
    model: {
      value: _vm.currentStage,
      callback: function ($$v) {
        _vm.currentStage = $$v;
      },
      expression: "currentStage"
    }
  })], 1)], 1)], 1), _c('v-col', {
    staticClass: "black--text right__border",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-card', {
    staticClass: "pa-0 ma-0 mx-2",
    attrs: {
      "rounded": "0",
      "elevation": "0"
    }
  }, [_c('v-card-text', {
    staticClass: "pa-0 ma-0 black--text",
    attrs: {
      "id": "report-skills"
    }
  }, [_c('v-row', {
    staticClass: "text-body-1 font-weight-medium"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm._v("Skills")]), _c('v-col', [_vm._v("Score")])], 1), _vm._l(_vm.testSkills, function (sk) {
    return _c('v-row', {
      key: _vm.skillScoreKey(sk),
      staticClass: "text-body-1 px-0 pb-3"
    }, [_c('v-col', [_vm._v(_vm._s(sk.title))]), _c('v-col', [_c('report-score', {
      attrs: {
        "percentile": _vm.getSkillPercentile(sk.id)
      }
    })], 1)], 1);
  })], 2), _c('v-card-actions', {
    staticClass: "px-0 ma-0 mt-8"
  }, [_c('v-row', {
    staticClass: "text-body-1 font-weight-medium score__border"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm._v("Overall Score:")]), _c('v-col', [_vm.hasCompleted ? _c('report-score', {
    attrs: {
      "percentile": (_vm$candidateReport3 = _vm.candidateReport) === null || _vm$candidateReport3 === void 0 ? void 0 : _vm$candidateReport3.getPercentile()
    }
  }) : _c('span', [_vm._v("-")])], 1)], 1)], 1)], 1)], 1), _c('v-col', {
    staticClass: "black--text",
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('v-card', {
    staticClass: "pa-0 ma-0",
    attrs: {
      "elevation": "0"
    }
  }, [_c('v-card-text', {
    staticClass: "pa-0 ma-0 black--text",
    attrs: {
      "id": "report-anticheat"
    }
  }, [_c('v-row', {
    staticClass: "text-body-1 font-weight-medium"
  }, [_c('v-col', [_vm._v(" Anti Cheating Report ")])], 1), _vm._l(_vm.antiCheats, function (ac) {
    return _c('v-row', {
      key: _vm.acKey(ac)
    }, [_c('v-col', {
      staticClass: "text-body-1"
    }, [_vm._v(" " + _vm._s(ac.title) + " ")]), _c('v-col', [_vm._v(" " + _vm._s(ac.value) + " ")])], 1);
  })], 2)], 1)], 1)], 1), _c('v-row', {
    staticClass: "black--text"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', [_c('v-col', [_c('div', {
    staticClass: "text-body-1 font-weight-medium"
  }, [_vm._v("Private Notes")])])], 1), _c('v-row', [_c('v-col', [_c('div', {
    staticClass: "textarea-container"
  }, [_c('div', {
    staticClass: "textarea-wrapper"
  }, [_c('v-textarea', {
    staticClass: "custom-textarea",
    attrs: {
      "id": "notes",
      "rows": "5",
      "outlined": "",
      "placeholder": "Candidate Notes"
    },
    model: {
      value: _vm.candidateNotes,
      callback: function ($$v) {
        _vm.candidateNotes = $$v;
      },
      expression: "candidateNotes"
    }
  }), _c('button', {
    staticClass: "copy-button",
    on: {
      "click": _vm.copyText
    }
  }, [_c('v-icon', {
    attrs: {
      "color": _vm.copyIconColor
    }
  }, [_vm._v(" " + _vm._s(_vm.mdiContentCopy) + " ")])], 1)], 1)])])], 1)], 1), _c('v-col', {
    staticClass: "d-flex flex-column-reverse"
  }, [_c('div', {
    staticClass: "pb-8 d-flex justify-space-between"
  }, [_c('v-btn', {
    staticClass: "text-body-1 text-none",
    attrs: {
      "color": "primary",
      "disabled": !_vm.isDirty
    },
    on: {
      "click": _vm.saveCandidateInfo
    }
  }, [_vm._v(" Save Notes ")])], 1)])], 1), ((_vm$timeline = _vm.timeline) === null || _vm$timeline === void 0 ? void 0 : _vm$timeline.length) > 0 ? _c('v-row', [_c('v-col', [_c('h3', {
    staticClass: "section-title"
  }, [_vm._v("Test timeline")])])], 1) : _vm._e(), ((_vm$timeline2 = _vm.timeline) === null || _vm$timeline2 === void 0 ? void 0 : _vm$timeline2.length) > 0 ? _c('v-row', [_c('v-col', [_c('v-timeline', {
    attrs: {
      "dense": ""
    }
  }, _vm._l(_vm.timeline, function (item, idx) {
    var _vm$assessment2, _vm$candidateReport4;
    return _c('v-timeline-item', {
      key: _vm.timelineKey(item, idx),
      attrs: {
        "icon": item.icon,
        "icon-color": item.color,
        "color": "white"
      }
    }, [_c('report-timeline-item', {
      attrs: {
        "item": item,
        "assessment-id": (_vm$assessment2 = _vm.assessment) === null || _vm$assessment2 === void 0 ? void 0 : _vm$assessment2.getId(),
        "candidate-id": (_vm$candidateReport4 = _vm.candidateReport) === null || _vm$candidateReport4 === void 0 ? void 0 : _vm$candidateReport4.getCandidateId()
      }
    })], 1);
  }), 1)], 1)], 1) : _vm._e(), (_vm$candidateReport5 = _vm.candidateReport) !== null && _vm$candidateReport5 !== void 0 && _vm$candidateReport5.getSkillScoresList() && _vm.candidateStatus === 'Completed' ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card-title', {
    staticClass: "primary--text"
  }, [_vm._v(" Skill breakdown ")]), _c('v-card', {
    staticClass: "ma-0 pa-5",
    attrs: {
      "elevation": "0",
      "outlined": ""
    }
  }, [_c('v-card-text', {
    attrs: {
      "id": "report-answers"
    }
  }, [_c('skill-report')], 1)], 1)], 1)], 1) : _vm._e(), _vm.resumeLink && _vm.resumeLink !== '' ? _c('v-row', [_c('v-col', [_c('v-card-title', {
    staticClass: "primary--text"
  }, [_vm._v(" Resume ")]), _c('pdf', {
    attrs: {
      "src": _vm.resumeLink
    }
  })], 1)], 1) : _vm._e()], 1), _c('v-tour', {
    attrs: {
      "name": "sampleReportTour",
      "steps": _vm.sampleReportTour
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }