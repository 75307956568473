<template>
  <v-expansion-panels>
    <v-expansion-panel
      v-for="(item, i) in skillScores"
      :key="skillKey(item, i)"
      class="my-2"
    >
      <v-expansion-panel-header
        class="font-weight-medium text-body-1 d-flex flex-row justify-space-between"
      >
        <v-row>
          <v-col>{{ item.getSkillPreview()?.getTitle() }}</v-col>
          <v-col :md="4" align-self="end">
            <report-score :percentile="item.getPercentile()" />
          </v-col>
          <v-col class="mr-3 text-caption" :md="2" align-self="end">
            {{ questions(item.getQuestionAnswerList().length) }}</v-col
          >
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="text-subtitle-1">
        <div
          class="question"
          v-for="(fullQ, i) in item.getQuestionAnswerList()"
          :key="questionKey(fullQ, i)"
        >
          <report-skill-question :question-answer="fullQ" />
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import { mapState } from 'vuex'
import ReportSkillQuestion from './ReportSkillQuestion.vue'
import { mdiTimer, mdiCheckboxMultipleMarked } from '@mdi/js'
import formattedTimeMixin from '@/mixins/formattedTimeMixin'
import ReportScore from './ReportScore.vue'
import { buildKey } from '@/utils/helper'

export default {
  components: { ReportSkillQuestion, ReportScore },
  data() {
    return {
      mdiTimer,
      mdiCheckboxMultipleMarked,
    }
  },
  mixins: [formattedTimeMixin],
  computed: {
    ...mapState('recruiter', ['candidateReport']),
    skillScores() {
      return this.candidateReport?.getSkillScoresList()
    },
  },
  methods: {
    skillDuration(item) {
      return this.displayTime(
        item?.getSkillPreview()?.getMetaData()?.getTimeLimit(),
      )
    },
    questions(count) {
      return count + ' question' + (count !== 1 ? 's' : '')
    },
    skillKey(item, i) {
      return buildKey('skillKey', [
        this.$route.path,
        item.getSkillPreview()?.getId(),
        i,
      ])
    },
    questionKey(fullQ, i) {
      return buildKey('questionKey', [
        this.$route.path,
        fullQ.getQuestionView()?.getId(),
        i,
      ])
    },
  },
  created() {},
}
</script>
