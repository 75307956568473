var render = function render(){
  var _vm$candidateReport;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "d-flex flex-row-reverse"
  }, [_c('v-dialog', {
    attrs: {
      "width": "500"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "text-none text-body-1",
          attrs: {
            "color": "primary",
            "text": ""
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          attrs: {
            "left": "",
            "color": "primary"
          }
        }, [_vm._v(" " + _vm._s(_vm.mdiEmail) + " ")]), _vm._v(" Send Mail ")], 1)];
      }
    }]),
    model: {
      value: _vm.emailDialog,
      callback: function ($$v) {
        _vm.emailDialog = $$v;
      },
      expression: "emailDialog"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "text-h5 primary--text font-weight-bold"
  }, [_vm._v(" Mail " + _vm._s((_vm$candidateReport = _vm.candidateReport) === null || _vm$candidateReport === void 0 ? void 0 : _vm$candidateReport.getName()) + " ")]), _c('v-card-text', [_c('p', {
    staticClass: "text-caption"
  }, [_vm._v("Select an option to send an email")]), _c('v-form', {
    ref: "emailForm"
  }, [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-list', _vm._l(_vm.emailTemplateTypes, function (option, index) {
    return _c('v-list-item', {
      key: index
    }, [_c('v-checkbox', {
      staticClass: "square-checkbox",
      attrs: {
        "label": option,
        "value": option,
        "color": "primary"
      },
      model: {
        value: _vm.selectedOption,
        callback: function ($$v) {
          _vm.selectedOption = $$v;
        },
        expression: "selectedOption"
      }
    })], 1);
  }), 1)], 1), _c('v-col', {
    staticClass: "align-self-end",
    attrs: {
      "cols": "6"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-end"
  }, [_c('v-btn', {
    staticClass: "py-3 px-5 text-none text-body-1",
    attrs: {
      "color": "primary",
      "loading": _vm.sendingMail,
      "disabled": _vm.selectedOption === null || _vm.selectedOption === '' || _vm.selectedOption === undefined
    },
    on: {
      "click": _vm.sendMail
    }
  }, [_vm._v(" Send Email ")])], 1)])], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }