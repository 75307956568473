<template>
  <div>
    <v-card elevation="0" class="mb-5">
      <v-card-title>
        <v-simple-table>
          <template slot:default>
            <thead>
              <tr>
                <th v-if="displayDuration" class="text-left">Time Taken</th>
                <th v-if="attempts" class="text-left">Attempts</th>
                <th class="text-left">Score</th>
                <th class="text-left">Max Score</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td v-if="displayDuration" class="text-left">
                  {{ displayDuration }}
                </td>
                <td v-if="attempts" class="text-left">
                  {{ attempts }}
                </td>
                <td class="text-left">
                  {{ Math.round(100 * questionAnswer.getRawScore()) / 100.0 }}
                </td>
                <td class="text-left">
                  {{ questionAnswer.getMaxScore() }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-title>
      <v-card-text v-if="questionType === 'MCQ'">
        <html-view :html="currentQuestionHtml"></html-view>
        <v-row align="center">
          <v-col
            cols="3"
            v-for="(opt, idx) in mcqOptions"
            :key="optionKey(opt, idx)"
          >
            <v-checkbox
              multiple
              v-model="candidateMcqAnswer"
              :value="opt.getId()"
              class="radio__option"
              :class="
                correctMcqAnswer?.includes(opt.getId())
                  ? 'radio__option--correct'
                  : 'radio__option--incorrect'
              "
              :color="isMcqOptionAnswerCorrect(opt.getId()) ? 'success' : 'red'"
              readonly
              hide-details
            >
              <template v-slot:label>
                <html-view :html="opt.getStatementHtml()" />
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-else-if="questionType === 'PROBLEM'">
        <html-view class="my-5" :html="currentQuestionHtml"></html-view>
        <v-row v-if="!noSubmission && bestSubmission" class="flex-column">
          <v-card elevation="0" outlined rounded="lg">
            <v-card-title class="text-body-1">
              Best Candidate Attempt
            </v-card-title>
            <v-card-text>
              <!-- make sure opening pre and template is on same line -->
              <!-- otherwise indentation weird on display -->
              <code-view
                :code="bestSubmission?.getSubmission()?.getCode()"
                :lang="language"
                :availableLanguages="[language]"
              />
            </v-card-text>
          </v-card>
        </v-row>
        <v-row class="pa-5" v-else>
          <div v-if="noSubmission">No submission considered</div>
          <div v-else>Loading ...</div>
        </v-row>
      </v-card-text>
      <v-card-text v-else-if="questionType === 'SUBJECTIVE'">
        <v-row v-if="!isAssigningScore" :hidden="isAssigningScore">
          <v-col cols="2">
            <v-btn text @click="setIsAssigningScore" color="primary" outlined>
              Assign Score
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="isAssigningScore" :hidden="!isAssigningScore">
          <v-col cols="3">
            <v-text-field
              ref="scoreInput"
              outlined
              dense
              v-model="scoreInput"
              :value="scoreInput"
              @keyup.enter="assignScore"
              placeholder="enter candidate score"
              :rules="[
                (v) => !!v || !Number.isNaN(v) || 'Enter Valid Number',
                (v) =>
                  (v && v >= 0 && v <= questionAnswer?.getMaxScore()) ||
                  'Score should be between 0 to ' +
                    questionAnswer.getMaxScore(),
              ]"
            >
            </v-text-field>
          </v-col>
          <v-col cols="2">
            <v-btn
              text
              @click="assignScore"
              color="primary"
              outlined
              :disabled="
                !(
                  scoreInput &&
                  scoreInput >= 0 &&
                  scoreInput <= questionAnswer?.getMaxScore()
                )
              "
            >
              Save
            </v-btn>
          </v-col>
        </v-row>

        <div class="my-5" v-html="currentQuestionHtml"></div>
        <v-row v-if="!noSubmission" class="flex-column">
          <v-card elevation="0" outlined rounded="lg">
            <v-card-title class="text-body-1">
              Candidates' Submission
            </v-card-title>
            <v-card-text>
              <!-- make sure opening pre and template is on same line -->
              <!-- otherwise indentation weird on display -->
              <div v-html="currentAnswerData.getSubjectiveAnswer()"></div>
            </v-card-text>
          </v-card>
        </v-row>
        <v-row class="pa-5" v-else>
          <div v-if="noSubmission">No submission considered</div>
          <div v-else>Loading ...</div>
        </v-row>
      </v-card-text>
    </v-card>
    <v-divider></v-divider>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import HtmlView from '@/components/HtmlView.vue'
import CodeView from '@/components/CodeView.vue'
import { prettyduration, buildKey } from '../../utils/helper'

export default {
  components: { HtmlView, CodeView },
  data() {
    return {
      bestSubmission: null,
      noSubmission: false,
      isAssigningScore: false,
      scoreInput: 0,
    }
  },
  props: {
    questionAnswer: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('recruiter', ['candidateReport']),
    ...mapState('recruiter', ['assessment']),
    ...mapState('judge', ['fetchSubmissionDetailStatus']),
    ...mapGetters('candidate', ['getContentTypes']),
    ...mapGetters('judge', ['languageKeys']),
    testId() {
      return this.candidateReport?.getTestId()
    },
    candidateId() {
      return this.candidateReport?.getCandidateId()
    },
    question() {
      return this.questionAnswer?.getQuestionView()
    },
    answer() {
      return this.questionAnswer?.getCompleteAnswerData()
    },
    questionType() {
      return this.getContentTypes[this.question?.getType()]
    },
    attempts() {
      return this.answer?.getProblemAnswerData()?.getSubmissionPreviewList()
        .length
    },
    currentQuestionHtml() {
      if (this.questionType === 'MCQ') {
        return this.question?.getDataView()?.getMcqView()?.getStatementHtml()
      } else if (this.questionType === 'SUBJECTIVE') {
        return this.question
          ?.getDataView()
          ?.getSubjectiveView()
          ?.getDescriptionHtml()
      } else if (this.questionType === 'PROBLEM') {
        return this.question
          ?.getDataView()
          ?.getProblemView()
          ?.getStatementView()
      } else {
        return `<div>Error loading question!</div>`
      }
    },
    language() {
      if (this.bestSubmission) {
        return this.languageKeys[
          this.bestSubmission?.getSubmission()?.getLanguage()
        ]
      }
    },
    mcqOptions() {
      if (this.questionType === 'MCQ') {
        return this.question?.getDataView()?.getMcqView()?.getOptionList()
      }
      return null
    },
    currentAnswerData() {
      if (this.questionType === 'MCQ') {
        return this.answer?.getMcqAnswerData()
      } else if (this.questionType === 'PROBLEM') {
        return this.answer?.getProblemAnswerData()
      } else if (this.questionType === 'SUBJECTIVE') {
        return this.answer?.getSubjectiveAnswerData()
      }
    },
    correctMcqAnswer() {
      if (this.questionType === 'MCQ')
        return this.currentAnswerData?.getCorrectOptionIdList()
    },
    candidateMcqAnswer() {
      if (this.questionType === 'MCQ' && !this.noSubmission)
        return this.currentAnswerData?.getOptionIdList()
    },
    mcqAllCorrect() {
      if (
        this.noSubmission ||
        this.correctMcqAnswer?.length !== this.candidateMcqAnswer?.length
      ) {
        return false
      }
      const union = Array.from(
        new Set([...this.correctMcqAnswer, ...this.candidateMcqAnswer]),
      )
      if (union.length !== this.correctMcqAnswer.length) {
        return false
      }
      return true
    },

    displayDuration() {
      if (
        !this.questionAnswer?.getTimeInfo() ||
        !this.questionAnswer?.getTimeInfo()?.getStartedAt()
      )
        return undefined
      const duration =
        (this.questionAnswer.getTimeInfo().getEndedAt() -
          this.questionAnswer.getTimeInfo().getStartedAt()) /
        1000
      return prettyduration(duration)
    },
  },
  methods: {
    ...mapActions('judge', ['fetchSubmissionDetail']),
    ...mapActions('recruiter', [
      'assignCandidateScore',
      'fetchCandidateReport',
    ]),
    fetchBestSubmission() {
      if (this.questionType === 'PROBLEM' && !this.noSubmission) {
        this.fetchSubmissionDetail({
          submissionId: this.currentAnswerData
            .getBestSubmissionPreview()
            ?.getId(),
        })
          .then((r) => {
            this.bestSubmission = r
          })
          .catch((r) => {
            this.noSubmission = true
          })
      }
    },
    isMcqOptionAnswerCorrect(optId) {
      return (
        this.correctMcqAnswer?.includes(optId) ===
        this.candidateMcqAnswer?.includes(optId)
      )
    },
    optionKey(opt, idx) {
      return buildKey('optionKey', [
        this.$route.path,
        this.questionAnswer?.getQuestionView().getId(),
        opt.getId(),
        idx,
      ])
    },
    setIsAssigningScore() {
      this.isAssigningScore = true
    },
    assignScore() {
      this.assignCandidateScore({
        score: this.scoreInput,
        qid: this.question?.getId(),
        testId: this.testId,
        cid: this.candidateId,
      })
        .then((r) => {
          this.isAssigningScore = false
          this.$store.dispatch('notifs/addNotif', {
            text: 'Score assigned successfully!',
            type: 'success',
          })
          this.fetchReport()
        })
        .catch((err) => {
          console.log('Error assigning score ..', err)
          this.$store.dispatch('notifs/addNotif', {
            text: err.message || 'Score assignment failed, please try again!',
            type: 'error',
          })
          this.isAssigningScore = false
        })
    },
    fetchReport() {
      this.candidateReport = null
      this.fetchCandidateReport({
        cid: this.$route.params.userId,
        testId: this.assessment?.getId(),
      }).catch((err) => {
        this.$store.dispatch('notifs/addNotif', {
          text: `Something went wrong! Please refresh and try again!`,
          type: 'error',
        })
      })
    },
  },
  created() {
    if (!this.answer) {
      // if completeAnswerData in proto field
      // is undefined
      this.noSubmission = true
    }
    this.isAssigningScore = false
    this.scoreInput = this.questionAnswer?.getRawScore()
    this.fetchBestSubmission()
  },
}
</script>
<style scoped>
.radio__option {
  padding: 0.5rem;
  border-radius: 1rem;
  border: 2px solid #c4c4c4;
}
.radio__option--correct {
  border: 2px solid #4caf50;
}
.radio__option--incorrect {
  border: 2px solid #c4c4c4;
}

.v-expansion-panel::before {
  box-shadow: none !important;
}
</style>
