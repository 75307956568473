var render = function render(){
  var _vm$bestSubmission, _vm$bestSubmission$ge, _vm$questionAnswer2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card', {
    staticClass: "mb-5",
    attrs: {
      "elevation": "0"
    }
  }, [_c('v-card-title', [_c('v-simple-table', [[_c('thead', [_c('tr', [_vm.displayDuration ? _c('th', {
    staticClass: "text-left"
  }, [_vm._v("Time Taken")]) : _vm._e(), _vm.attempts ? _c('th', {
    staticClass: "text-left"
  }, [_vm._v("Attempts")]) : _vm._e(), _c('th', {
    staticClass: "text-left"
  }, [_vm._v("Score")]), _c('th', {
    staticClass: "text-left"
  }, [_vm._v("Max Score")])])]), _c('tbody', [_c('tr', [_vm.displayDuration ? _c('td', {
    staticClass: "text-left"
  }, [_vm._v(" " + _vm._s(_vm.displayDuration) + " ")]) : _vm._e(), _vm.attempts ? _c('td', {
    staticClass: "text-left"
  }, [_vm._v(" " + _vm._s(_vm.attempts) + " ")]) : _vm._e(), _c('td', {
    staticClass: "text-left"
  }, [_vm._v(" " + _vm._s(Math.round(100 * _vm.questionAnswer.getRawScore()) / 100.0) + " ")]), _c('td', {
    staticClass: "text-left"
  }, [_vm._v(" " + _vm._s(_vm.questionAnswer.getMaxScore()) + " ")])])])]], 2)], 1), _vm.questionType === 'MCQ' ? _c('v-card-text', [_c('html-view', {
    attrs: {
      "html": _vm.currentQuestionHtml
    }
  }), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, _vm._l(_vm.mcqOptions, function (opt, idx) {
    var _vm$correctMcqAnswer;
    return _c('v-col', {
      key: _vm.optionKey(opt, idx),
      attrs: {
        "cols": "3"
      }
    }, [_c('v-checkbox', {
      staticClass: "radio__option",
      class: (_vm$correctMcqAnswer = _vm.correctMcqAnswer) !== null && _vm$correctMcqAnswer !== void 0 && _vm$correctMcqAnswer.includes(opt.getId()) ? 'radio__option--correct' : 'radio__option--incorrect',
      attrs: {
        "multiple": "",
        "value": opt.getId(),
        "color": _vm.isMcqOptionAnswerCorrect(opt.getId()) ? 'success' : 'red',
        "readonly": "",
        "hide-details": ""
      },
      scopedSlots: _vm._u([{
        key: "label",
        fn: function () {
          return [_c('html-view', {
            attrs: {
              "html": opt.getStatementHtml()
            }
          })];
        },
        proxy: true
      }], null, true),
      model: {
        value: _vm.candidateMcqAnswer,
        callback: function ($$v) {
          _vm.candidateMcqAnswer = $$v;
        },
        expression: "candidateMcqAnswer"
      }
    })], 1);
  }), 1)], 1) : _vm.questionType === 'PROBLEM' ? _c('v-card-text', [_c('html-view', {
    staticClass: "my-5",
    attrs: {
      "html": _vm.currentQuestionHtml
    }
  }), !_vm.noSubmission && _vm.bestSubmission ? _c('v-row', {
    staticClass: "flex-column"
  }, [_c('v-card', {
    attrs: {
      "elevation": "0",
      "outlined": "",
      "rounded": "lg"
    }
  }, [_c('v-card-title', {
    staticClass: "text-body-1"
  }, [_vm._v(" Best Candidate Attempt ")]), _c('v-card-text', [_c('code-view', {
    attrs: {
      "code": (_vm$bestSubmission = _vm.bestSubmission) === null || _vm$bestSubmission === void 0 ? void 0 : (_vm$bestSubmission$ge = _vm$bestSubmission.getSubmission()) === null || _vm$bestSubmission$ge === void 0 ? void 0 : _vm$bestSubmission$ge.getCode(),
      "lang": _vm.language,
      "availableLanguages": [_vm.language]
    }
  })], 1)], 1)], 1) : _c('v-row', {
    staticClass: "pa-5"
  }, [_vm.noSubmission ? _c('div', [_vm._v("No submission considered")]) : _c('div', [_vm._v("Loading ...")])])], 1) : _vm.questionType === 'SUBJECTIVE' ? _c('v-card-text', [!_vm.isAssigningScore ? _c('v-row', {
    attrs: {
      "hidden": _vm.isAssigningScore
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary",
      "outlined": ""
    },
    on: {
      "click": _vm.setIsAssigningScore
    }
  }, [_vm._v(" Assign Score ")])], 1)], 1) : _vm._e(), _vm.isAssigningScore ? _c('v-row', {
    attrs: {
      "hidden": !_vm.isAssigningScore
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-text-field', {
    ref: "scoreInput",
    attrs: {
      "outlined": "",
      "dense": "",
      "value": _vm.scoreInput,
      "placeholder": "enter candidate score",
      "rules": [function (v) {
        return !!v || !Number.isNaN(v) || 'Enter Valid Number';
      }, function (v) {
        var _vm$questionAnswer;
        return v && v >= 0 && v <= ((_vm$questionAnswer = _vm.questionAnswer) === null || _vm$questionAnswer === void 0 ? void 0 : _vm$questionAnswer.getMaxScore()) || 'Score should be between 0 to ' + _vm.questionAnswer.getMaxScore();
      }]
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.assignScore.apply(null, arguments);
      }
    },
    model: {
      value: _vm.scoreInput,
      callback: function ($$v) {
        _vm.scoreInput = $$v;
      },
      expression: "scoreInput"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary",
      "outlined": "",
      "disabled": !(_vm.scoreInput && _vm.scoreInput >= 0 && _vm.scoreInput <= ((_vm$questionAnswer2 = _vm.questionAnswer) === null || _vm$questionAnswer2 === void 0 ? void 0 : _vm$questionAnswer2.getMaxScore()))
    },
    on: {
      "click": _vm.assignScore
    }
  }, [_vm._v(" Save ")])], 1)], 1) : _vm._e(), _c('div', {
    staticClass: "my-5",
    domProps: {
      "innerHTML": _vm._s(_vm.currentQuestionHtml)
    }
  }), !_vm.noSubmission ? _c('v-row', {
    staticClass: "flex-column"
  }, [_c('v-card', {
    attrs: {
      "elevation": "0",
      "outlined": "",
      "rounded": "lg"
    }
  }, [_c('v-card-title', {
    staticClass: "text-body-1"
  }, [_vm._v(" Candidates' Submission ")]), _c('v-card-text', [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.currentAnswerData.getSubjectiveAnswer())
    }
  })])], 1)], 1) : _c('v-row', {
    staticClass: "pa-5"
  }, [_vm.noSubmission ? _c('div', [_vm._v("No submission considered")]) : _c('div', [_vm._v("Loading ...")])])], 1) : _vm._e()], 1), _c('v-divider')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }