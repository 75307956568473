var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-expansion-panels', _vm._l(_vm.skillScores, function (item, i) {
    var _item$getSkillPreview;
    return _c('v-expansion-panel', {
      key: _vm.skillKey(item, i),
      staticClass: "my-2"
    }, [_c('v-expansion-panel-header', {
      staticClass: "font-weight-medium text-body-1 d-flex flex-row justify-space-between"
    }, [_c('v-row', [_c('v-col', [_vm._v(_vm._s((_item$getSkillPreview = item.getSkillPreview()) === null || _item$getSkillPreview === void 0 ? void 0 : _item$getSkillPreview.getTitle()))]), _c('v-col', {
      attrs: {
        "md": 4,
        "align-self": "end"
      }
    }, [_c('report-score', {
      attrs: {
        "percentile": item.getPercentile()
      }
    })], 1), _c('v-col', {
      staticClass: "mr-3 text-caption",
      attrs: {
        "md": 2,
        "align-self": "end"
      }
    }, [_vm._v(" " + _vm._s(_vm.questions(item.getQuestionAnswerList().length)))])], 1)], 1), _c('v-expansion-panel-content', {
      staticClass: "text-subtitle-1"
    }, _vm._l(item.getQuestionAnswerList(), function (fullQ, i) {
      return _c('div', {
        key: _vm.questionKey(fullQ, i),
        staticClass: "question"
      }, [_c('report-skill-question', {
        attrs: {
          "question-answer": fullQ
        }
      })], 1);
    }), 0)], 1);
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }