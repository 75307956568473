var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.percentile !== undefined ? _c('span', [_c('v-chip', {
    staticClass: "mt-1",
    attrs: {
      "color": _vm.scoreSummary.color,
      "small": "",
      "pill": ""
    }
  }, [_vm.scoreSummary.text !== '' ? _c('span', [_vm._v(_vm._s(_vm.scoreSummary.text) + " | ")]) : _vm._e(), _vm.percentile.getMaxScore() === 0 ? _c('span', {
    staticClass: "ml-1"
  }, [_vm._v(" " + _vm._s(_vm.displayScore(_vm.percentile.getScore(), _vm.raw)))]) : _c('span', {
    staticClass: "ml-1"
  }, [_vm._v(" " + _vm._s(_vm.displayScore(_vm.percentile.getRawScore(), true)) + " / " + _vm._s(_vm.displayScore(_vm.percentile.getMaxScore(), true)) + " ")])])], 1) : _c('span', [_vm._v("-")]);

}
var staticRenderFns = []

export { render, staticRenderFns }