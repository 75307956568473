<template>
  <span v-if="percentile !== undefined">
    <v-chip :color="scoreSummary.color" small pill class="mt-1">
      <span v-if="scoreSummary.text !== ''">{{ scoreSummary.text }} | </span>
      <span class="ml-1" v-if="percentile.getMaxScore() === 0">
        {{ displayScore(percentile.getScore(), raw) }}</span
      >
      <span class="ml-1" v-else>
        {{ displayScore(percentile.getRawScore(), true) }} /
        {{ displayScore(percentile.getMaxScore(), true) }}
      </span>
    </v-chip>
  </span>
  <span v-else>-</span>
</template>

<script>
import { prettyPercent } from '@/utils/helper'
const ScorePercentile = proto.io.codedrills.proto.user.ScorePercentile

export default {
  props: {
    percentile: {
      type: ScorePercentile,
      required: false,
    },
    raw: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  computed: {
    displayColor() {
      if (!this.percentile) return 'grey'
      console.log('p: ', this.percentile.toObject())
      const p = this.rankPercentile
      return 'blue lighten-2'
      if (p < 0.25) return 'red lighten-2'
      else if (p < 0.5) return 'orange'
      else if (p < 0.75) return 'yellow lighten-2'
      else if (p < 0.9) return 'blue'
      else if (p < 0.95) return 'cyan'
      else if (p < 0.99) return 'light-green'
      else return 'green'
    },
    rankPercentile() {
      if (!this.percentile) return 0
      return (
        (1.0 *
          (this.percentile.getLesserScoreCount() +
            this.percentile.getEqualScoreCount())) /
        this.percentile.getTotal()
      )
    },
    scoreSummary() {
      if (!this.percentile) return '-'

      if (this.percentile.getScore() < 0.00001)
        return {
          color: 'orange lighten-2',
          text: 'below average',
        }
      if (this.percentile.getTotal() === 1)
        return {
          color: 'grey',
          text: '',
        }
      if (this.percentile.getRank() === 1)
        return {
          color: 'primary',
          text: 'top score',
        }
      var topPercentile =
        (1.0 *
          (this.percentile.getGreaterScoreCount() +
            this.percentile.getEqualScoreCount())) /
        this.percentile.getTotal()
      if (topPercentile <= 0.01)
        return {
          color: 'green',
          text: 'top 1%',
        }
      if (topPercentile <= 0.05)
        return {
          color: 'green lighten-1',
          text: 'top 5%',
        }
      if (topPercentile <= 0.1)
        return {
          color: 'cyan lighten-1',
          text: 'top 10%',
        }
      if (topPercentile <= 0.25)
        return {
          color: 'cyan lighten-2',
          text: 'top 25%',
        }
      if (this.percentile.getScore() >= this.percentile.getAverageScore())
        return {
          color: 'blue lighten-2',
          text: 'above average',
        }
      else
        return {
          color: 'orange lighten-2',
          text: 'below average',
        }
    },
  },
  methods: {
    displayScore(score, raw) {
      console.log('Score and raw', score, this.raw)
      if (!score) score = 0
      return raw ? Math.round(score * 100) / 100.0 : prettyPercent(score)
    },
  },
}
</script>
