<template>
    <v-row class="d-flex flex-row-reverse">
      <v-dialog v-model="emailDialog" width="500">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            text
            class="text-none text-body-1"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon left color="primary">
              {{ mdiEmail }}
            </v-icon>
            Send Mail
          </v-btn>
        </template>
  
        <v-card>
          <v-card-title class="text-h5 primary--text font-weight-bold">
            Mail {{ candidateReport?.getName() }}
          </v-card-title>
  
          <v-card-text>
            <p class="text-caption">Select an option to send an email</p>
            <v-form ref="emailForm">
              <v-row justify="center">
                <v-col cols="6">
                  <v-list>
                    <v-list-item v-for="(option, index) in emailTemplateTypes" :key="index">
                      <v-checkbox
                        v-model="selectedOption"
                        :label="option"
                        :value="option"
                        class="square-checkbox"
                        color="primary"
                      ></v-checkbox>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="6" class="align-self-end">
                  <div class="d-flex justify-end">
                    <v-btn
                      class="py-3 px-5 text-none text-body-1"
                      color="primary"
                      @click="sendMail"
                      :loading="sendingMail"
                      :disabled="selectedOption === null || selectedOption === '' || selectedOption === undefined"
                    >
                      Send Email
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </template>
  
  <script>

 import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
 import {mdiEmail} from '@mdi/js'
  export default {
    data() {
      return {
        emailDialog: false,
        sendingMail: false,
        mdiEmail,
        selectedOption: null,
        emailTemplateTypes:[
            'Selected',
            'Rejected',
            // 'In Progress',
        ],
        emailOptions: {
        Selected: 1,
        Rejected: 2,
        "In Progress": 3,
      },
      };
    },
    computed: {
    ...mapState('recruiter', [
      'assessment',
      'candidateReport',
    ]),
    ...mapGetters('recruiter', [
      'emailTypes',
    ]),
    },
    methods: {
        ...mapActions('recruiter', [
      'emailCandidate']),
        sendMail() {
            this.sendingMail = true
            console.log("Sending mail ....", this.selectedOption, this.emailOptions[this.selectedOption])
            this.emailCandidate({
                testId: this.assessment?.getId(),
                cid: this.candidateReport?.getCandidateId(),
                etype: this.emailTypes[this.emailOptions[this.selectedOption]],
            })
                .then((r) => {
                this.sendingMail = false
                this.emailDialog = false
                this.$store.dispatch('notifs/addNotif', {
                    text: 'Emailed Candidate Successfully!',
                    type: 'success',
                })
                this.sendingMail = false
                this.emailDialog = false
                })
                .catch((err) => {
                this.$store.dispatch('notifs/addNotif', {
                    text: err.message ?? 'Error occured',
                    type: 'error',
                })
                this.sendingMail = false
                console.log(err)
                })
        },
    },
    created() {
        console.log("Email component created", this.assessment, this.candidateReport)
    },
  };
  </script>
<style>
.square-checkbox .v-input--selection-controls__input {
  border-radius: 0;
}
.dialog-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

</style>
  