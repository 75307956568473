<template>
  <div>
    <v-btn @click="loadVideo()" icon v-if="!videoBlob">
      <v-icon color="primary">{{ mdiPlay }}</v-icon> Play
    </v-btn>
    <video v-if="videoBlob" ref="video" :src="source" controls></video>
    <error v-else-if="loadingError"
      >An error ocurred while loading this page. Please try again</error
    >
    <loader v-else-if="loadingInProgress"></loader>
  </div>
</template>

<script>
import { mdiPlay } from '@mdi/js'
import Loader from '@/components/Loader.vue'
import Error from '@/components/Error.vue'
import { mapActions, mapState } from 'vuex'
import { ApiCallStatus } from '@/utils/api'

export default {
  props: {
    log: {
      type: String,
      required: true,
    },
    assessmentId: {
      type: Number,
    },
    candidateId: {
      type: String,
    },
  },
  data() {
    return {
      player: null,
      videoBlob: null,
      videoType: null,
      type: null,
      active: false,
      mdiPlay,
    }
  },
  components: {
    Loader,
    Error,
  },
  computed: {
    ...mapState('recruiter', ['getVideoStatus']),
    source() {
      return URL.createObjectURL(
        new Blob([this.videoBlob], { type: this.videoType }),
      )
    },
    loadingInProgress() {
      return this.active && this.getVideoStatus === ApiCallStatus.CALLING
    },
    loadingError() {
      return this.active && this.getVideoStatus === ApiCallStatus.ERROR
    },
  },
  methods: {
    ...mapActions('recruiter', ['getVideo']),
    startPlayback() {
      const video = this.$refs.video
      video.play()
    },
    loadVideo() {
      this.active = true
      console.log('Ids..', this.assesmentId, this.candidateId)
      this.getVideo({
        url: this.log,
        testId: this.assessmentId,
        candidateId: this.candidateId,
      })
        .then((res) => {
          console.log('Response: ', res)
          this.videoType = res.getType()
          this.videoBlob = res.getVideo_asU8()
          console.log(
            'Single candidate Video blob: and type ',
            this.videoBlob,
            this.videoType,
          )
          this.startPlayback()
        })
        .finally(() => {
          this.active = false
        })
    },
  },
}
</script>

<style scoped>
html,
body {
  margin: 0 !important;
  padding: 0 !important;
}
</style>
